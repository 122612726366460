import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, CircularProgress } from '@mui/material';
import {
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppWidgetSummary,
} from '../sections/@dashboard/app';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const theme = useTheme();
  const [planData, setPlanData] = useState([]);
  const [monitorStatus, setMonitorStatus] = useState([0, 0]);
  const [newsUpdateData, setNewsUpdateData] = useState([]);
  const [paymentInvoiceDate, setPaymentInvoiceData] = useState([]);
  const [paymentInvoiceBalance, setPaymentInvoiceBalance] = useState([]);
  const [activityHistory, setActivityHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchPlanData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/getUserSubscriptionPlansWithIp`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data.success) {
          const planDataArray = Object.keys(response.data.data).map((key) => ({
            subscriptionPlanId: key,
            planName: response.data.data[key].planName,
            remainingIpCount: response.data.data[key].ipCount,
          }));
          setPlanData(planDataArray);
        } else {
          console.error('API response indicates failure:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching plan data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    const fetchMonitorStatusData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/getMonitorsAddressStatusForDashboard`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data.success) {
          const MonitorsStatusArray = response.data.data;
          setMonitorStatus(MonitorsStatusArray);
        } else {
          console.error('API response indicates failure:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching MonitorStatus data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    const fetchNewsUpdate = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/getAllNewsUpdate`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data.success) {
          const newsUpdateDataArray = response.data.data;
          setNewsUpdateData(newsUpdateDataArray);
        } else {
          console.error('API response indicates failure:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching news update data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    const fetchPaymentInvoice = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/getPaymentInvoice`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data.success) {
          const paymentInvoiceDate = response.data.data1;
          const paymentInvoiceBalance = response.data.data2;
          setPaymentInvoiceData(paymentInvoiceDate);
          setPaymentInvoiceBalance(paymentInvoiceBalance);
        } else {
          console.error('API response indicates failure:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching payment invoice data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    const fetchActivityHistory = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/getUserActivityById`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data.success) {
          const activityHistoryData = response.data.data;
          setActivityHistory(activityHistoryData);
        } else {
          console.error('API response indicates failure:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching history data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    const token = localStorage.getItem('token');
    if (!token) {
      window.location.href = '/login';
    }

    const fetchData = async () => {
      await Promise.all([
        fetchPlanData(),
        fetchMonitorStatusData(),
        fetchNewsUpdate(),
        fetchPaymentInvoice(),
        fetchActivityHistory(),
      ]);
    };

    fetchData();
  }, []);

  const planDataArray = Object.values(planData);
  const getBackgroundColorClass = (planName) => {
    switch (planName) {
      case 'Standard':
        return 'primary';
      case 'Basic':
        return 'warning';
      case 'Free Trail':
        return 'secondary';
      case 'Contact Sales':
        return 'success';
      default:
        return 'info';
    }
  };
  const getIconClass = (planName) => {
    switch (planName) {
      case 'Standard':
        return 'ant-design:crown-filled';
      case 'Basic':
        return 'ant-design:sketch-circle-filled';
      case 'Free Trail':
        return 'ant-design:build-filled';
      case 'Contact Sales':
        return 'ant-design:control-filled';
      default:
        return 'ant-design:build-filled';
    }
  };

  return (
    <>
      <Helmet>
        <title> Dashboard | WatchNod </title>
        <meta
          name="description"
          content="Welcome to the WatchNod Dashboard. Manage your subscription plans, monitor statuses, payment invoices, and stay updated with the latest news and activities."
        />
        <meta
          name="keywords"
          content="WatchNod, Dashboard, Subscription Plans, Monitor Status, Payment Invoices, News Updates, Activity History"
        />
        <meta property="og:title" content="Dashboard | WatchNod" />
        <meta
          property="og:description"
          content="The central hub for managing WatchNod subscriptions, monitoring, payments, and staying informed with updates and activities."
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="/assets/images/dashboard-preview.jpg" />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Dashboard | WatchNod" />
        <meta
          name="twitter:description"
          content="Welcome to the WatchNod Dashboard. Stay updated and manage everything in one place."
        />
        <meta name="twitter:image" content="/assets/images/dashboard-preview.jpg" />
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'WebPage',
            name: 'Dashboard | WatchNod',
            description:
              'Central hub for managing subscriptions, monitoring statuses, payments, news, and activities in WatchNod.',
            url: window.location.href,
          })}
        </script>
      </Helmet>

      <Container maxWidth="xl">
        <h1>
          <Typography variant="h3" gutterBottom>
            Hi, Welcome To WatchNod
          </Typography>
        </h1>

        <Grid container spacing={3}>
          {planDataArray.map((plan, index) => (
            <Grid key={index} item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                title={plan.planName}
                color={getBackgroundColorClass(plan.planName)}
                total={plan.remainingIpCount !== 0 ? plan.remainingIpCount : '0'}
                icon={getIconClass(plan.planName)}
              />
            </Grid>
          ))}
        </Grid>
        <Grid container spacing={3} style={{ marginTop: '1%' }}>
          <Grid item xs={12} md={6} lg={8} style={{ justifyContent: 'left', alignItems: 'center', minHeight: '50vh' }}>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <>
                <AppWebsiteVisits
                  title="Payment Invoice"
                  subheader="Total Pay Per Month in ($)"
                  chartLabels={paymentInvoiceDate}
                  chartData={[
                    {
                      name: 'Total Spent',
                      type: 'column',
                      fill: 'solid',
                      data: paymentInvoiceBalance,
                    },
                  ]}
                />
              </>
            )}
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="Monitors Status"
              chartData={[
                { label: 'Running Monitors', value: monitorStatus[0] },
                { label: 'Suspended Monitors', value: monitorStatus[1] },
              ]}
              chartColors={[theme.palette.primary.main, theme.palette.warning.main]}
            />
          </Grid>

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates
              title="Conversion Rates"
              subheader="(+43%) than last year"
              chartData={[
                { label: 'Italy', value: 400 },
                { label: 'Japan', value: 430 },
                { label: 'China', value: 448 },
                { label: 'Canada', value: 470 },
                { label: 'France', value: 540 },
                { label: 'Germany', value: 580 },
                { label: 'South Korea', value: 690 },
                { label: 'Netherlands', value: 1100 },
                { label: 'United States', value: 1200 },
                { label: 'United Kingdom', value: 1380 },
              ]}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject
              title="Current Subject"
              chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
              chartData={[
                { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
                { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
                { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
              ]}
              chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
            />
          </Grid> */}

          <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate
              title="News Update"
              list={
                newsUpdateData
                  ? newsUpdateData.map((data, index) => {
                      if (data) {
                        return {
                          id: data._id,
                          title: data.newsTitle,
                          description: data.newsDescription,
                          image: `/assets/images/covers/cover_${index + 1}.jpg`,
                          postedAt: new Date(data.createdAt),
                        };
                      }
                      return {
                        id: `no-update-${index}`,
                        title: 'No Update',
                        description: 'There is no update available.',
                        image: '/assets/images/placeholder.jpg',
                        postedAt: new Date(),
                      };
                    })
                  : []
              }
            />
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <AppOrderTimeline
              title="Last 5 Activity"
              list={activityHistory.map((data, index) =>
                data
                  ? {
                      id: data._id,
                      title: data.activityTitle,
                      type: `order${index + 1}`,
                      time: data.createdAt,
                    }
                  : {
                      id: `no-update-${index}`,
                      title: 'No Update',
                      type: '',
                      time: new Date(),
                    }
              )}
            />
          </Grid>

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite
              title="Traffic by Site"
              list={[
                {
                  name: 'FaceBook',
                  value: 323234,
                  icon: <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} />,
                },
                {
                  name: 'Google',
                  value: 341212,
                  icon: <Iconify icon={'eva:google-fill'} color="#DF3E30" width={32} />,
                },
                {
                  name: 'Linkedin',
                  value: 411213,
                  icon: <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} />,
                },
                {
                  name: 'Twitter',
                  value: 443232,
                  icon: <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} />,
                },
              ]}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppTasks
              title="Tasks"
              list={[
                { id: '1', label: 'Create FireStone Logo' },
                { id: '2', label: 'Add SCSS and JS files if required' },
                { id: '3', label: 'Stakeholder Meeting' },
                { id: '4', label: 'Scoping & Estimations' },
                { id: '5', label: 'Sprint Showcase' },
              ]}
            />
          </Grid> */}
        </Grid>
      </Container>
    </>
  );
}
