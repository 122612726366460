import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Container } from '@mui/material';
import DocumentDetail from '../landingPage/page/Documentation/DocumentDetail';

export default function DocumentationPage() {
  return (
    <>
      <Helmet>
        <title>Documentation | WatchNod</title>
        <meta
          name="description"
          content="Explore comprehensive documentation for WatchNod. Learn how to utilize features, manage settings, and maximize your experience with our platform."
        />
        <meta
          name="keywords"
          content="WatchNod Documentation, User Guide, Features, Settings, Tutorials, Help Center"
        />
        <meta property="og:title" content="Documentation | WatchNod" />
        <meta
          property="og:description"
          content="Comprehensive guide to get started and navigate WatchNod features effectively."
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="/assets/images/documentation-preview.jpg" />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Documentation | WatchNod" />
        <meta
          name="twitter:description"
          content="Learn how to make the most of WatchNod with detailed documentation and guides."
        />
        <meta name="twitter:image" content="/assets/images/documentation-preview.jpg" />
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'WebPage',
            name: 'Documentation | WatchNod',
            description: 'Comprehensive guide to get started and navigate WatchNod features effectively.',
            url: window.location.href,
          })}
        </script>
      </Helmet>

      <Container maxWidth="xl">
        <DocumentDetail />
      </Container>
    </>
  );
}
