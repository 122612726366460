import React, { useState, useEffect } from 'react';
import { Container, Typography, Box } from '@mui/material';
import FeatureBox from './FeatureBox';
import featureImage1 from './images/6.webm';
import featureImage2 from './images/3.webm';
import featureImage3 from './images/5.webm';
import featureImage4 from './images/7.webm';

export default function Feature() {
  const featureBoxes = [
    {
      image: featureImage1,
      title: 'Real-time Monitoring',
      description: 'Monitor your servers in real-time to ensure optimal performance.',
      animationClass: 'slide-left',
    },
    {
      image: featureImage2,
      title: 'Server Health Metrics',
      description: 'Track important server health metrics to identify issues proactively.',
      animationClass: 'slide-right',
    },
    {
      image: featureImage3,
      title: 'Alerts and Notifications',
      description: 'Receive alerts and notifications when critical events occur.',
      animationClass: 'slide-left',
    },
    {
      image: featureImage4,
      title: 'Historical Data and Reports',
      description: 'Access historical data and generate detailed reports for analysis.',
      animationClass: 'slide-right',
    },
  ];

  const [scrollPosition, setScrollPosition] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    const handleScroll = () => {
      const newPosition = window.scrollY;
      setScrollPosition(newPosition);
    };

    // Throttling scroll events
    const throttle = setTimeout(handleScroll, 200); // Adjust timeout as needed

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);

    return () => {
      clearTimeout(throttle);
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const featureImageStyles = {
    width: '100%',
    height: 'auto',
    aspectRatio: '16 / 9', // This ensures consistent aspect ratio
    minHeight: '200px', // Prevent images from collapsing
    willChange: 'transform, opacity',
  };

  const boxStyles = (animationClass) => ({
    width: isMobile ? '100%' : '48%',
    padding: '20px',
    transition: 'transform 1.65s ease-in-out, opacity 1.65s ease-in-out',
    position: 'relative', // Keep positions stable
    transform: isMobile
      ? 'translateX(0)'
      : `translateX(${scrollPosition > 200 ? 0 : animationClass === 'slide-left' ? -100 : 100}px)`,
    opacity: isMobile ? 1 : scrollPosition > 200 ? 1 : 0,
    willChange: 'transform, opacity', // Notify the browser of upcoming changes
  });

  // Ensure no late scroll-triggered animation that could cause shifts:
  useEffect(() => {
    // Remove the delay if possible
    setScrollPosition(window.scrollY);
  }, []);

  return (
    <Container
      id="feature"
      style={{
        textAlign: 'center',
        marginTop: '2.5%',
        contain: 'layout', // This limits layout shifts to the container itself
      }}
    >
      <Box sx={{ marginBottom: 0 }}>
        <Typography
          sx={{
            fontFamily: 'Poppins',
            fontWeight: '600',
            fontSize: '26px',
            lineHeight: '48px',
            color: '#161414',
            textAlign: {
              xs: 'center',
            },
          }}
        >
          Features
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', minHeight: '500px' }}>
        {featureBoxes.map((feature, index) => (
          <Box
            key={index}
            style={{
              ...boxStyles(feature.animationClass),
            }}
          >
            <FeatureBox
              image={feature.image}
              title={feature.title}
              description={feature.description}
              styles={featureImageStyles}
            />
          </Box>
        ))}
      </Box>
    </Container>
  );
}
