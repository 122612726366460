import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Stack, IconButton, InputAdornment, TextField, Typography, CircularProgress, Button } from '@mui/material';
import PropTypes from 'prop-types';
import Iconify from '../../../components/iconify';

LoginForm.propTypes = {
  setShowLogin: PropTypes.func,
};

function LoginForm({ setShowLogin }) {
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [errors, setErrors] = useState({ email: '', password: '', message: '' });
  const [isLoading, setIsLoading] = useState(false);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({ ...errors, [name]: '' });
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.email) {
      newErrors.email = 'Email is required';
    } else {
      const trimmedEmail = formData.email.trim();
      if (!isValidEmail(trimmedEmail)) {
        newErrors.email = 'Invalid email address';
      }
    }

    if (!formData.password) {
      newErrors.password = 'Password is required';
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleLogin = async (event) => {
    event.preventDefault();

    if (validateForm()) {
      const apiUrl = `${process.env.REACT_APP_API_URL}/login`;

      const loginData = {
        email: formData.email,
        password: formData.password,
      };

      setIsLoading(true);

      try {
        const response = await axios.post(apiUrl, loginData);

        if (response.data.success) {
          localStorage.setItem('token', response.data.token);
          localStorage.setItem('userId', response.data.data._id);
          localStorage.setItem('role', response.data.data.role);

          toast.success(`Welcome ${response.data.data.fullName}`, {
            autoClose: 2000,
          });
          setTimeout(() => {
            window.location.replace('/dashboard');
            if (typeof setShowLogin === 'function') {
              setShowLogin(false);
            }
          }, 1000);
        } else {
          toast.error(response.data.message, {
            autoClose: 2000,
          });
        }
      } catch (errorResponse) {
        console.error(errorResponse);
        if (errorResponse.response && errorResponse.response.data) {
          toast.error(
            <>
              {errorResponse.response?.data?.message || 'An error occurred.'} <a href="/about">Contact Us</a>
            </>
          );
        } else {
          toast.error('Server Not Found.');
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <form onSubmit={handleLogin}>
        <Stack spacing={3}>
          <TextField
            name="email"
            label="Email address"
            value={formData.email}
            onChange={handleInputChange}
            error={!!errors.email}
            helperText={errors.email}
            autoComplete="email"
            fullWidth
            required
            variant="outlined"
            inputProps={{ 'aria-label': 'Email address' }}
          />

          <TextField
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            value={formData.password}
            onChange={handleInputChange}
            error={!!errors.password}
            helperText={errors.password}
            autoComplete="password"
            fullWidth
            required
            variant="outlined"
            inputProps={{ 'aria-label': 'Password' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                    aria-label="Toggle password visibility"
                  >
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        <Stack direction="row" alignItems="right" justifyContent="space-between" sx={{ my: 2 }}>
          <Link to="/forgotPassword" underline="hover">
            Forgot password?
          </Link>
        </Stack>

        {errors.message && (
          <Typography variant="body2" color="error" sx={{ mb: 2 }}>
            {errors.message}
          </Typography>
        )}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          startIcon={isLoading ? <CircularProgress style={{ color: 'white' }} /> : null}
          sx={{
            justifyContent: 'center',
            display: 'flex',
          }}
          {...(isLoading ? { loading: 'true' } : {})}
        >
          {isLoading ? 'Logging in...' : 'Login'}
        </Button>
      </form>
    </>
  );
}

export default LoginForm;
