import React, { useState } from 'react';
import { Box } from '@mui/material';
import backgroundImage from './images/bg.jpg';

const Header = () => {
  const [imageError, setImageError] = useState(false);

  return (
    <Box
      sx={(theme) => ({
        [theme.breakpoints.down('md')]: {
          flex: '1',
          alignSelf: 'center',
        },
        [theme.breakpoints.up('md')]: {
          flex: '2',
          alignSelf: 'flex-end',
        },
      })}
    >
      {/* Hidden H1 for SEO */}
      <h1
        style={{
          position: 'absolute',
          width: '1px',
          height: '1px',
          margin: '-1px',
          padding: '0',
          border: '0',
          clip: 'rect(0, 0, 0, 0)',
          clipPath: 'inset(50%)',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
      >
        WatchNod: Server Monitoring System
      </h1>

      {imageError ? (
        <p
          style={{
            width: '100%',
            height: '100%',
            marginBottom: 20,
            objectFit: 'cover',
          }}
        >
          Loading WatchNod Landscape
        </p>
      ) : (
        <img
          src={backgroundImage}
          alt="Loading WatchNod Landscape"
          title="WatchNod Header Landscape"
          width="100%"
          height="100%"
          loading="eager"
          style={{
            width: '100%',
            height: '100%',
            marginBottom: 20,
            objectFit: 'cover',
          }}
          onError={() => setImageError(true)}
        />
      )}
    </Box>
  );
};

export default Header;
