import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, Paper } from '@mui/material';

function FeatureBox(props) {
  const [isHovered, setIsHovered] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const boxStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '16px',
    borderRadius: '8px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
    textAlign: 'center',
    transform: isHovered && !isMobile ? 'scale(1.05)' : 'scale(1)',
    transition: 'transform 0.2s ease',
  };

  const videoStyle = {
    marginBottom: '8px',
    width: '50%',
    height: 'auto',
  };

  return (
    <Paper
      elevation={3}
      style={boxStyle}
      onMouseEnter={() => !isMobile && setIsHovered(true)}
      onMouseLeave={() => !isMobile && setIsHovered(false)}
    >
      <div style={videoStyle}>
        <video autoPlay loop muted playsInline style={{ width: '100%' }}>
          <source src={props.image} type="video/webm" />
          <source src={props.image.replace('.webm', '.mp4')} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      <div>
        <Typography style={{ fontSize: '1.25rem', fontWeight: 'bold' }}>{props.title}</Typography>
        <Typography variant="body2">{props.description}</Typography>
      </div>
    </Paper>
  );
}

FeatureBox.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default FeatureBox;
