import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { Container, Typography, Stack, Dialog } from '@mui/material';
import { ContactSalesData, CustomizePlan } from '../sections/@dashboard/contactSale';

const StyledCard = styled.div`
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
`;
export default function ContactSales() {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [reloadData, setReloadData] = useState(false);
  const navigate = useNavigate();

  const openForm = (id) => {
    setSelectedUser(id);
    setIsFormOpen(true);
  };
  const closeForm = () => {
    setIsFormOpen(false);
    navigate('/dashboard/contact-sales');
  };

  return (
    <>
      <Helmet>
        <title>Contact Sale | WatchNod </title>
        <meta
          name="description"
          content="Contact our sales team to customize a plan that fits your needs. Optimize your WatchNod experience with tailored solutions."
        />
        <meta
          name="keywords"
          content="Contact Sales, WatchNod, Customize Plan, Sales Team, Tailored Solutions"
        />
        <meta property="og:title" content="Contact Sales | WatchNod" />
        <meta
          property="og:description"
          content="Get in touch with the WatchNod sales team to customize your plan. Experience the perfect solution tailored for your requirements."
        />
        <meta property="og:image" content="https://www.watchnod.com/static/images/contact-sales.png" />
        <meta property="og:url" content="https://www.watchnod.com/dashboard/contact-sales" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Contact Sales | WatchNod" />
        <meta
          name="twitter:description"
          content="Reach out to the WatchNod sales team to create a customized plan that suits your needs perfectly."
        />
        <meta name="twitter:image" content="https://www.watchnod.com/static/images/contact-sales.png" />

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": "Contact Sales | WatchNod",
            "description": "Get in touch with the WatchNod sales team to customize a plan that fits your needs.",
            "url": "https://www.watchnod.com/dashboard/contact-sales",
            "image": "https://www.watchnod.com/static/images/contact-sales.png",
            "publisher": {
              "@type": "Organization",
              "name": "WatchNod",
              "url": "https://www.watchnod.com",
            },
            "mainEntityOfPage": {
              "@type": "WebPage",
              "@id": "https://www.watchnod.com/dashboard/contact-sales"
            }
          })}
        </script>
      </Helmet>

      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <h1>
            <Typography variant="h3" gutterBottom>
              User Customize Plan
            </Typography>
          </h1>
        </Stack>

        <ContactSalesData openForm={openForm} key={reloadData} />

        <Dialog open={isFormOpen} onClose={closeForm}>
          <StyledCard>
            <CustomizePlan
              closeForm={closeForm}
              selectedUser={selectedUser}
              reloadData={() => setReloadData(!reloadData)}
            />
          </StyledCard>
        </Dialog>
      </Container>
    </>
  );
}
